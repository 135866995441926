import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Logo from './images/nav-logo.jpg';
import Grid from '@mui/material/Grid';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from '@mui/material';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardMedia } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';



const drawerWidth = 240;
const navItems = ['About Us', 'Courses', 'Features', 'Our Subscriptions', 'Job Assistance', 'Contact Us'];


function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: '#FFF0C9', height: '100%' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Box
          component="img"
          src={Logo}
          alt="Logo"
          sx={{
            width: { xs: '100px', sm: '150px', md: '200px' },
            height: 'auto',
          }}
        />
      </Typography>
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText
                primary={item}
                sx={{ color: '#910C0D', fontWeight: 600, textTransform: 'capitalize', fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' } }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          backgroundColor: { xs: '#FFF0C9', sm: '#FFF0C9' },
          py: 2,
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, color: '#910C0D' }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { sm: 'block' } }}>
            <Box
              component="img"
              src={Logo}
              alt="Logo"
              sx={{
                width: { xs: '100px', sm: '150px', md: '200px' },
                height: 'auto',
              }}
            />
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: '#910C0D', fontWeight: 600, textTransform: 'capitalize', fontSize: { xs: '0.9rem', sm: '1.1rem', md: '18px' } }}>
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box sx={{ flexGrow: 1, pt: { xs: 3, sm: 5 } }}>
        <Toolbar />
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <Box sx={{ bgcolor: '#007200', height: { xs: '10vh', sm: '10vh', md: '15vh' }, display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
              <Typography variant="h1" sx={{ color: 'white', fontSize: { xs: '25px', sm: '1.1rem', md: '65px' } }}>
                Call us:
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{
                bgcolor: '#FA8400',
                height: { xs: '10vh', sm: '10vh', md: '15vh' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Link
                href="tel:+919477008515"
                underline="none"
                sx={{
                  color: '#910C0D',
                  fontSize: { xs: '25px', sm: '1.1rem', md: '65px' },
                  fontWeight: 'bold',
                  wordSpacing: { xs: '3px', sm: '3px', md: '5px' },
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CallIcon sx={{ color: '#910C0D', fontSize: { xs: '25px', sm: '1.1rem', md: '60px' } }} />
                +91 9477008515
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ position: 'relative', width: '100%' }}>
          <Box
            component="img"
            src="https://img.freepik.com/premium-photo/group-college-students-studying-with-notes-laptops-preparing-exams-campus-quad_1218867-452874.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid"
            alt="Best Educational institute in India"
            sx={{
              width: '100%',
              height: { xs: '70vh', sm: '70vh', md: '80vh' },
              objectFit: 'cover',
            }}
          />

          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              padding: { xs: '20px', sm: '30px', md: '50px' },
              border: '2px dashed #fff',
              borderRadius: '8px',
            }}
          >
            <Typography
              variant="h1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
                fontSize: { xs: '60px', sm: '70px', md: '100px' },
                marginBottom: { xs: '10px', sm: '15px', md: '20px' },
              }}
            >
              <LocalLibraryIcon
                sx={{
                  color: '#fff',
                  fontSize: { xs: '60px', sm: '70px', md: '100px' },
                  marginRight: { xs: '10px', sm: '20px', md: '20px' },
                }}
              />
              EDUCATION
            </Typography>

            <Typography
              variant="h1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
                fontSize: { xs: '60px', sm: '70px', md: '100px' },
              }}
            >
              <AutoStoriesIcon
                sx={{
                  color: '#fff',
                  fontSize: { xs: '60px', sm: '70px', md: '100px' },
                  marginRight: { xs: '10px', sm: '20px', md: '20px' },
                }}
              />
              CAREER
            </Typography>
          </Box>
        </Box>

        {/* Card Component */}
        <Container maxWidth={false} sx={{ py: 4, mx: 0, backgroundColor: '#FFEEC0' }}>
          <Typography variant="h5" sx={{ fontSize: '3rem', mt: 3, fontWeight: 'bold', color: '#910C0D', pl: { xs: 0, sm: 0, md: 3 } }}>
            About Us
          </Typography>

          <Card elevation={0} sx={{ backgroundColor: 'transparent', borderRadius: 0, }}>
            <Grid container spacing={2}>
              <Grid item md={5} xs={12}>
                <CardContent>
                  <Typography variant="body1" sx={{ color: '#910C0D', mt: 5, fontWeight: '500' }}>
                    Braineduskills is a pioneering educational initiative focused on equipping individuals with the skills and knowledge necessary for personal and professional growth in today's dynamic world. The organization operates with the mission of creating a transformative learning experience that bridges the gap between traditional education and practical, industry-relevant skills.

                    At the core of Braineduskills is a commitment to innovation and accessibility. Through a diverse range of courses, workshops, and resources, the platform caters to learners of all ages and backgrounds. By offering flexible, high-quality online and in-person learning options, Braineduskills empowers students to learn at their own pace, accommodating various learning styles and schedules. Its curriculum spans a wide range of fields, including technology, business, health, personal development, and creative arts, ensuring there’s something for everyone.
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item md={7} xs={12}>
                <Box
                  component="img"
                  src="https://img.freepik.com/free-photo/mentor-training-students-business-school_1262-15004.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid"
                  alt="About Us"
                  sx={{
                    width: '100%',
                    maxHeight: { xs: 'auto', md: 400 },
                    borderRadius: '0',
                    objectFit: 'cover',
                    border: '2px dashed #910C0D',
                  }}
                />

              </Grid>

            </Grid>
          </Card>
          <Card elevation={0} sx={{ backgroundColor: 'transparent', mb: 0, borderRadius: 0, }}>
            <Grid container spacing={2}>
              <CardContent>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#910C0D',
                    mt: 2,
                    fontWeight: '500',
                    pl: { xs: 0, sm: 0, md: 3 },
                  }}
                >
                  Ultimately, Braineduskills is more than just an educational platform; it’s a movement aimed at empowering individuals to realize their potential, stay competitive, and pursue lifelong learning. Through a commitment to quality, accessibility, and innovation, Braineduskills continues to redefine what it means to learn and grow in the 21st century.
                </Typography>

              </CardContent>
            </Grid>
          </Card>
          <Card elevation={0} sx={{ backgroundColor: 'transparent', borderRadius: 0, }}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start', // Aligns the button to the end
                  }}
                >
                  <Button
                    variant="contained"
                    href="#contained-buttons"
                    sx={{
                      borderRadius: 0,
                      fontSize: '1rem',
                      backgroundColor: '#910C0D',
                      fontWeight: 'bold',
                      mb: 2,
                      mt: 5,
                      ml: { xs: 0, sm: 0, md: 3 }
                    }}
                  >
                    Read More
                  </Button>
                </Grid>

              </Grid>
              <Grid item md={6} xs={12}>
              </Grid>

            </Grid>
          </Card>
        </Container>

        <Container maxWidth={false} sx={{ py: 0, mx: 0, backgroundColor: '#FFEEC0' }}>
          <Typography variant="h5" sx={{ fontSize: '3rem', mt: 0, fontWeight: 'bold', color: '#910C0D', pl: { xs: 0, sm: 0, md: 3 } }}>
            What We Offer
          </Typography>
          <Typography variant="h5" sx={{ fontSize: '2rem', pb: 3, mt: 2, color: '#693607', pl: { xs: 0, sm: 0, md: 3 } }}>
            Unlock your way to success
          </Typography>
        </Container>
        <Card elevation={0} sx={{ backgroundColor: '#910C0D', borderRadius: 0, py: { xs: 0, sm: 0, md: 5 }, px: { xs: 0, sm: 0, md: 5 }, pl: { xs: 0, sm: 0, md: 5 } }}>
          <Grid container spacing={5}>
            <Grid item md={6} xs={12}>
              <Box
                component="img"
                src="https://img.freepik.com/free-photo/smiling-students-studying-together-table_23-2147850724.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid"
                alt="About Us"
                sx={{
                  width: '100%',
                  maxHeight: { xs: 'auto', md: 400 },
                  borderRadius: '0',
                  objectFit: 'cover',
                  border: '2px dashed #FECE48',
                  mb: 3
                }}
              />
              <Typography variant="body1" sx={{ color: '#FECE48', mt: 5, fontWeight: 'bold', fontSize: '25px', }}>
                BrainEduSkills is a trusted Education Platform
              </Typography>
              <Typography variant="body1" sx={{ color: '#FECE48', fontWeight: 'bold', fontSize: '25px', }}>
                Where We Build students for NEET UG
              </Typography>
            </Grid>
            <Grid item md={5} xs={12}>
              <CardContent>
                <Typography variant="body1" sx={{ color: '#FECE48', fontWeight: '500' }}>
                  A defining feature of Braineduskills is its focus on real-world applicability. The courses are designed in collaboration with industry experts and professionals, providing learners with insights that are directly applicable in the workplace. Rather than focusing solely on theory, Braineduskills emphasizes practical skills, problem-solving, and hands-on projects, preparing students to thrive in fast-evolving industries. The platform incorporates project-based assessments, case studies, and simulations, offering learners a chance to apply what they've learned in simulated real-world scenarios.
                </Typography>
                <Typography variant="body1" sx={{ color: '#FECE48', mt: 2, fontWeight: '500' }}>
                  Braineduskills also prioritizes community and mentorship. Recognizing the importance of guidance and support in the learning process, the organization has built a network of mentors, educators, and peers who provide personalized feedback and encouragement. Learners can connect with industry professionals and like-minded individuals, fostering a collaborative learning environment that promotes growth and networking.
                </Typography>
                <Typography variant="body1" sx={{ color: '#FECE48', mt: 2, fontWeight: '500' }}>
                  Moreover, Braineduskills is dedicated to affordability and inclusivity, offering various pricing options and financial aid opportunities to ensure that quality education is within reach for everyone. The organization believes that learning should not be limited by financial barriers, and it continuously works to make its programs accessible to a broader audience.
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
        <Typography variant="h5" sx={{ fontSize: '3rem', mt: 0, fontWeight: 'bold', color: '#007200', pl: { xs: 0, sm: 0, md: 3 }, py: { xs: 0, sm: 0, md: 3 }, textAlign: 'center', backgroundColor: '#FECE48' }}>
          Features
        </Typography>
        <Card
          elevation={0}
          sx={{
            backgroundImage:
              'url(https://img.freepik.com/premium-photo/digital-illustration-graduation-cap-globe-with-educational-icons-wireframe-style-dark-green-background-symbolizing-concept-global-education-3d-rendering_670147-99714.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: 0,
            py: { xs: 5, sm: 5, md: 5 },
            px: { xs: 0, sm: 5, md: 5 },
            position: 'relative',
            color: '#FECE48',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#0046004a',
              zIndex: 1,
            }}
          />
          <Container maxWidth={false} className="row">
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={3} display="flex" justifyContent="center" className='center'>
                <Card
                  sx={{
                    height: '300px',
                    width: '300px',
                    borderRadius: 0,
                    position: 'relative',
                    color: '#FECE48',
                    overflow: 'hidden',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CardMedia
                      component="img"
                      height="160"
                      sx={{
                        width: '160px',
                        borderRadius: '50%',
                      }}
                      image="https://img.freepik.com/free-photo/graduation-concept-with-students-holding-blank-certificate-template_23-2148201847.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid"
                      alt="Students watching a webinar and studying online"
                    />
                  </Box>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" sx={{
                      color: '#FECE48',
                      textAlign: 'center',
                    }}>
                      Experienced Faculties
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#FECE48',
                        textAlign: 'center',
                      }}
                    >
                      Experienced faculties bring expertise, dedication, and real-world insights to enrich students' learning and career development.
                    </Typography>
                  </CardContent>

                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3} display="flex" justifyContent="center">
                <Card
                  sx={{
                    height: '300px',
                    width: '300px',
                    borderRadius: 0,
                    position: 'relative',
                    color: '#FECE48',
                    overflow: 'hidden',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CardMedia
                      component="img"
                      height="160"
                      sx={{
                        width: '160px',
                        borderRadius: '50%',
                      }}
                      image="https://img.freepik.com/free-photo/crop-hand-writing-notebook-near-computer_23-2147863504.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid"
                      alt="Students watching a webinar and studying online"
                    />
                  </Box>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" sx={{
                      color: '#FECE48',
                      textAlign: 'center',
                    }}>
                      Module/Typing Notes
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#FECE48',
                        textAlign: 'center',
                      }}
                    >
                      Module and typing notes offer structured, clear, and accessible resources to enhance efficient student learning.
                    </Typography>
                  </CardContent>

                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3} display="flex" justifyContent="center">
                <Card
                  sx={{
                    height: '300px',
                    width: '300px',
                    borderRadius: 0,
                    position: 'relative',
                    color: '#FECE48',
                    overflow: 'hidden',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CardMedia
                      component="img"
                      height="160"
                      sx={{
                        width: '160px',
                        borderRadius: '50%',
                      }}
                      image="https://img.freepik.com/premium-photo/college-students-doing-homework-library_13339-152709.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid"
                      alt="Students watching a webinar and studying online"
                    />
                  </Box>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" sx={{
                      color: '#FECE48',
                      textAlign: 'center',
                    }}>
                      Free Demo Class
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#FECE48',
                        textAlign: 'center',
                      }}
                    >
                      Free demo class notes provide a preview of course quality, helping students gauge learning benefits.
                    </Typography>
                  </CardContent>

                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3} display="flex" justifyContent="center">
                <Card
                  sx={{
                    height: '300px',
                    width: '300px',
                    borderRadius: 0,
                    position: 'relative',
                    color: '#FECE48',
                    overflow: 'hidden',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CardMedia
                      component="img"
                      height="160"
                      sx={{
                        width: '160px',
                        borderRadius: '50%',
                      }}
                      image="https://img.freepik.com/free-photo/top-view-hand-correcting-grammar-mistakes_23-2150171443.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid"
                      alt="Students watching a webinar and studying online"
                    />
                  </Box>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" sx={{
                      color: '#FECE48',
                      textAlign: 'center',
                    }}>
                      Chapterwise Test
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#FECE48',
                        textAlign: 'center',
                      }}
                    >
                      Chapterwise tests reinforce understanding, assess progress, and build confidence through focused, structured evaluations.
                    </Typography>
                  </CardContent>

                </Card>
              </Grid>
            </Grid>
          </Container>
        </Card>
        <Typography variant="h5" sx={{ fontSize: '3rem', mt: 0, fontWeight: 'bold', color: '#910C0D', pl: { xs: 0, sm: 0, md: 3 }, pt: { xs: 0, sm: 0, md: 3 }, textAlign: 'center', backgroundColor: '#FFEEC0' }}>
          Courses
        </Typography>
        <Card
          elevation={0}
          sx={{
            backgroundColor: '#FFEEC0',
            borderRadius: 0,
            py: { xs: 5, sm: 5, md: 5 },
            px: { xs: 0, sm: 5, md: 5 },
            position: 'relative',
            color: '#FECE48',
            overflow: 'hidden',
          }}
        >
          <Container maxWidth={false} className="row" sx={{
            pb: { xs: 5, sm: 5, md: 5 },
          }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center" className='center'>
                <Card
                  sx={{
                    height: '330px',
                    width: '350px',
                    borderRadius: '20px',
                    position: 'relative',
                    color: '#FECE48',
                    overflow: 'hidden',
                    backgroundColor: '#ECD7AA',
                    boxShadow: 'none',
                    borderLeft: '2px dashed #910C0D',
                    borderRight: '2px dashed #910C0D',
                  }}
                >
                  <CardContent
                    sx={{

                    }}
                  >
                    <Typography gutterBottom variant="h5" component="div" sx={{
                      color: '#910C0D',
                      textAlign: 'center',
                      fontWeight: '600',
                      mt: 4,
                    }}>
                      NEET, AIIMS, IIT, <br />JEE
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#910C0D',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '18px',
                        mt: 2,
                      }}
                    >
                      Amity University, Noida
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#910C0D',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '18px',
                        mt: 2,
                      }}
                    >
                      B.Tech Computer Science and Engineering
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >

                      <Link href="https://mui.com/material-ui/react-link/" variant="body2" target='_blank'>
                        <Button sx={{
                          backgroundColor: '#910C0D',
                          color: '#F9CF63',
                          px: { xs: 2, sm: 3, md: 5 },
                          mt: 2
                        }}>
                          View
                        </Button>
                      </Link>
                    </Box>

                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
                <Card
                  sx={{
                    height: '330px',
                    width: '350px',
                    borderRadius: '20px',
                    position: 'relative',
                    color: '#FECE48',
                    overflow: 'hidden',
                    backgroundColor: '#ECD7AA',
                    boxShadow: 'none',
                    borderLeft: '2px dashed #910C0D',
                    borderRight: '2px dashed #910C0D',
                  }}
                >
                  <CardContent
                    sx={{

                    }}
                  >
                    <Typography gutterBottom variant="h5" component="div" sx={{
                      color: '#910C0D',
                      textAlign: 'center',
                      fontWeight: '600',
                      mt: 4,
                    }}>
                      JEE, JEE MAIN <br />(MAIN AND ADVANCE)
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#910C0D',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '18px',
                        mt: 3,
                      }}
                    >
                      Manipal Institute of Technology
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#910C0D',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '18px',
                        mt: 2,
                      }}
                    >
                      B.Tech Mechatronics <br />Engineering
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >

                      <Link href="https://mui.com/material-ui/react-link/" variant="body2" target='_blank'>
                        <Button sx={{
                          backgroundColor: '#910C0D',
                          color: '#F9CF63',
                          px: { xs: 2, sm: 3, md: 5 },
                          mt: 2
                        }}>
                          View
                        </Button>
                      </Link>
                    </Box>

                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
                <Card
                  sx={{
                    height: '330px',
                    width: '350px',
                    borderRadius: '20px',
                    position: 'relative',
                    color: '#FECE48',
                    overflow: 'hidden',
                    backgroundColor: '#ECD7AA',
                    boxShadow: 'none',
                    borderLeft: '2px dashed #910C0D',
                    borderRight: '2px dashed #910C0D',
                  }}
                >
                  <CardContent
                    sx={{

                    }}
                  >
                    <Typography gutterBottom variant="h5" component="div" sx={{
                      color: '#910C0D',
                      textAlign: 'center',
                      fontWeight: '600',
                      mt: 4,
                    }}>
                      PSC, SSC, ISC, IAS,<br /> IPS, RAIL
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#910C0D',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '18px',
                        mt: 3,
                      }}
                    >
                      Amity University, Noida
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#910C0D',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '18px',
                        mt: 2,
                      }}
                    >
                      B.Tech Computer Science and Engineering
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >

                      <Link href="https://mui.com/material-ui/react-link/" variant="body2" target='_blank'>
                        <Button sx={{
                          backgroundColor: '#910C0D',
                          color: '#F9CF63',
                          px: { xs: 2, sm: 3, md: 5 },
                          mt: 2
                        }}>
                          View
                        </Button>
                      </Link>
                    </Box>

                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Card>
        <Typography variant="h5" sx={{ fontSize: '3rem', mt: 0, fontWeight: 'bold', color: '#FECE48', pl: { xs: 0, sm: 0, md: 3 }, py: { xs: 0, sm: 0, md: 3 }, textAlign: 'center', backgroundColor: '#910C0D' }}>
          OUR NEET ACHIVERS
        </Typography>
        <Card
          elevation={0}
          sx={{
            backgroundColor: '#FECE48',
            borderRadius: 0,
            py: { xs: 5, sm: 5, md: 5 },
            px: { xs: 0, sm: 5, md: 5 },
            position: 'relative',
            color: '#FECE48',
            overflow: 'hidden',
          }}
        >
          <Container maxWidth={false} className="row" sx={{
            py: { xs: 4, sm: 4, md: 4 },
          }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={3} display="flex" justifyContent="center" className='center'>
                <Card
                  sx={{
                    height: '340px',
                    width: '300px',
                    borderRadius: '20px',
                    position: 'relative',
                    color: '#FECE48',
                    overflow: 'hidden',
                    backgroundColor: '#E7A43B',
                    boxShadow: 'none',
                    borderLeft: '2px solid #910C0D',
                    borderRight: '2px solid #910C0D',
                  }}
                >
                  <CardContent
                    sx={{

                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CardMedia
                        component="img"
                        height="160"
                        sx={{
                          width: '160px',
                          borderRadius: '50%',
                          mt: 2,
                        }}
                        image="https://img.freepik.com/premium-photo/man-wearing-glasses-blue-shirt-with-book-his-hand_1277828-29514.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid"
                        alt="Students watching a webinar and studying online"
                      />
                    </Box>
                    <Typography gutterBottom variant="h5" component="div" sx={{
                      color: '#910C0D',
                      textAlign: 'center',
                      fontWeight: '600',
                      mt: 3,
                    }}>
                      Pinaki Nair
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#910C0D',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '18px',
                      }}
                    >
                      Healthcare and Skill Development
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3} display="flex" justifyContent="center">
                <Card
                  sx={{
                    height: '340px',
                    width: '300px',
                    borderRadius: '20px',
                    position: 'relative',
                    color: '#FECE48',
                    overflow: 'hidden',
                    backgroundColor: '#E7A43B',
                    boxShadow: 'none',
                    borderLeft: '2px solid #910C0D',
                    borderRight: '2px solid #910C0D',
                  }}
                >
                  <CardContent
                    sx={{

                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CardMedia
                        component="img"
                        height="160"
                        sx={{
                          width: '160px',
                          borderRadius: '50%',
                          mt: 2,
                        }}
                        image="https://img.freepik.com/premium-photo/smiling-indian-student-girl-holding-books-educational-setting_1311447-6631.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid"
                        alt="Students watching a webinar and studying online"
                      />
                    </Box>
                    <Typography gutterBottom variant="h5" component="div" sx={{
                      color: '#910C0D',
                      textAlign: 'center',
                      fontWeight: '600',
                      mt: 3,
                    }}>
                      Shruti Bhattacharya
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#910C0D',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '18px',
                      }}
                    >
                      Software Development
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3} display="flex" justifyContent="center">
                <Card
                  sx={{
                    height: '340px',
                    width: '300px',
                    borderRadius: '20px',
                    position: 'relative',
                    color: '#FECE48',
                    overflow: 'hidden',
                    backgroundColor: '#E7A43B',
                    boxShadow: 'none',
                    borderLeft: '2px solid #910C0D',
                    borderRight: '2px solid #910C0D',
                  }}
                >
                  <CardContent
                    sx={{

                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CardMedia
                        component="img"
                        height="160"
                        sx={{
                          width: '160px',
                          borderRadius: '50%',
                          mt: 2,
                        }}
                        image="https://img.freepik.com/premium-photo/photo-portrait-smiling-college-student-holding-notebook-backpack-college-campus_763111-320445.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid"
                        alt="Students watching a webinar and studying online"
                      />
                    </Box>
                    <Typography gutterBottom variant="h5" component="div" sx={{
                      color: '#910C0D',
                      textAlign: 'center',
                      fontWeight: '600',
                      mt: 3,
                    }}>
                      Anmol Kartik
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#910C0D',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: '18px',
                      }}
                    >
                      Mechanical Engineer
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Card>
        <Typography variant="h5" sx={{ fontSize: '3rem', mt: 0, fontWeight: 'bold', color: '#FECE48', pl: { xs: 0, sm: 0, md: 3 }, py: { xs: 0, sm: 0, md: 3 }, textAlign: 'center', backgroundColor: '#910C0D' }}>
          GALLERY
        </Typography>
        <Card
          elevation={0}
          sx={{
            backgroundColor: '#FFEEC0',
            borderRadius: 0,
            py: { xs: 5, sm: 5, md: 5 },
            px: { xs: 0, sm: 5, md: 5 },
            position: 'relative',
            color: '#FECE48',
            overflow: 'hidden',
            borderTop: '10px solid #FECE48',
            borderBottom: '10px solid #FECE48',
          }}
        >
          <Container maxWidth={false} className="row" sx={{
            py: { xs: 4, sm: 4, md: 4 },
          }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center" className='center'>
                <Card
                  sx={{
                    height: '300px',
                    width: '370px',
                    borderRadius: '20px',
                    position: 'relative',
                    color: '#FECE48',
                    overflow: 'hidden',
                    backgroundColor: '#E7A43B',
                    boxShadow: 'none',
                    border: '2px solid #910C0D',
                  }}
                >
                  <Box
                    component="img"
                    src="https://img.freepik.com/free-photo/full-shot-friends-sitting-together_23-2149481206.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid"
                    alt="About Us"
                    sx={{
                      height: '300px',
                      width: '100%',
                      maxHeight: { xs: 'auto', md: 400 },
                      borderRadius: '0',
                      objectFit: 'cover',
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
                <Card
                  sx={{
                    height: '300px',
                    width: '370px',
                    borderRadius: '20px',
                    position: 'relative',
                    color: '#FECE48',
                    overflow: 'hidden',
                    backgroundColor: '#E7A43B',
                    boxShadow: 'none',
                    border: '2px solid #910C0D',
                  }}
                >
                  <Box
                    component="img"
                    src="https://img.freepik.com/premium-photo/group-happy-indian-medical-students-posing-photo-style-raw-stylize-250-job-id-5d847dedfac04f31b2a46bf3f00cfd3b_343960-74760.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid"
                    alt="About Us"
                    sx={{
                      height: '300px',
                      width: '100%',
                      maxHeight: { xs: 'auto', md: 400 },
                      borderRadius: '0',
                      objectFit: 'cover',
                    }}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
                <Card
                  sx={{
                    height: '300px',
                    width: '370px',
                    borderRadius: '20px',
                    position: 'relative',
                    color: '#FECE48',
                    overflow: 'hidden',
                    backgroundColor: '#E7A43B',
                    boxShadow: 'none',
                    border: '2px solid #910C0D',
                  }}
                >
                  <Box
                    component="img"
                    src="https://img.freepik.com/free-photo/graduate-female-shows-like-with-her-friends-graduation-gowns-holding-diploma-smiling-camera_496169-1308.jpg?uid=P119104888&ga=GA1.1.1106605636.1719834042&semt=ais_hybrid"
                    alt="About Us"
                    sx={{
                      height: '300px',
                      width: '100%',
                      maxHeight: { xs: 'auto', md: 400 },
                      borderRadius: '0',
                      objectFit: 'cover',
                    }}
                  />
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Card>
        <Card
          elevation={0}
          sx={{
            backgroundColor: '#007200',
            borderRadius: 0,
            py: { xs: 5, sm: 5, md: 5 },
            px: { xs: 0, sm: 5, md: 5 },
            position: 'relative',
            color: '#FECE48',
            overflow: 'hidden',
          }}
        >
          <Container maxWidth={false} className="row" sx={{}}>
            <Grid container spacing={2} justifyContent="center">
              {/* Contact Us Box 1 */}
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                justifyContent="center"
                sx={{
                  borderRight: { md: '2px dashed #FECE48' },
                }}
              >
                <Box>
                  <Typography variant="h5" sx={{ fontSize: '25px', mt: 0, fontWeight: 'bold', color: '#FECE48' }}>
                    CONTACT US:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: '25px', mt: 3, fontWeight: 'bold', color: '#FECE48' }}>
                    <Link href="tel:+919477008515" underline="none" sx={{ color: '#FECE48', fontSize: '20px' }}>
                      <CallIcon sx={{ color: '#FECE48', fontSize: '20px' }} />
                      +91 9477008515
                    </Link>
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: '25px', mt: 3, fontWeight: 'bold', color: '#FECE48' }}>
                    <Link href="mailto:braineduskills@gmail.com" underline="none" sx={{ display: 'flex', alignItems: 'center', color: '#FECE48', fontSize: '20px' }}>
                      <MailIcon sx={{ fontSize: '20px', mr: 1 }} />
                      braineduskills@gmail.com
                    </Link>
                  </Typography>
                </Box>
              </Grid>

              {/* Contact Us Box 2 */}
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                justifyContent="center"
                sx={{
                  borderRight: { md: '2px dashed #FECE48' },
                }}
              >
                <Box>
                  <Typography variant="h5" sx={{ fontSize: '25px', mt: 0, fontWeight: 'bold', color: '#FECE48' }}>
                    LINKS:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: '25px', mt: 3, fontWeight: 'bold', color: '#FECE48' }}>
                    <Link href="tel:+919477008515" underline="none" sx={{ color: '#FECE48', fontSize: '20px' }}>
                      
                    </Link>
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: '25px', mt: 3, fontWeight: 'bold', color: '#FECE48' }}>
                    <Link href="mailto:braineduskills@gmail.com" underline="none" sx={{ display: 'flex', alignItems: 'center', color: '#FECE48', fontSize: '20px' }}>
                      
                    </Link>
                  </Typography>
                </Box>
              </Grid>

              {/* Contact Us Box 3 */}
              <Grid item xs={12} md={4} display="flex" justifyContent="center">
                <Box>
                  <Typography variant="h5" sx={{ fontSize: '25px', mt: 0, fontWeight: 'bold', color: '#FECE48' }}>
                    ADDRESS:
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: '25px', mt: 3, fontWeight: 'bold', color: '#FECE48' }}>
                    <Link href="tel:+919477008515" underline="none" sx={{ color: '#FECE48', fontSize: '20px' }}>
                      <HomeIcon sx={{ color: '#FECE48', fontSize: '20px', mr: 1, }} />
                      Gr Floor, 26 Ananda Mohan Bose Road, Motijheel, Dumdum, Kolkata: 700074
                    </Link>
                  </Typography>
                  <Typography variant="h5" sx={{ fontSize: '25px', mt: 3, fontWeight: 'bold', color: '#FECE48' }}>
                    <Link href="mailto:braineduskills@gmail.com" underline="none" sx={{ display: 'flex', alignItems: 'center', color: '#FECE48', fontSize: '20px' }}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d779.0650072137864!2d88.41174034070764!3d22.620666072490074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sbGr%20Floor%2C%2026%20Ananda%20Mohan%20Bose%20Road%2C%20Motijheel%2C%20Dumdum%2C%20Kolkata%3A%20700074!5e1!3m2!1sen!2sin!4v1731498818463!5m2!1sen!2sin" width="400" height="150" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <hr style={{ border: '2px solid #FECE48', marginTop: '40px' }} />

            <Typography
              variant="body1"
              sx={{ textAlign: 'center', fontWeight: 500, fontSize: '20px', color: '#FECE48', mt: 2 }}
            >
              Copyright © BrainEduSkills | All Rights Reserved
            </Typography>
          </Container>





        </Card>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
